import React from 'react';
import P from 'prop-types';
import get from 'lodash.get';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';

import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Blogg = (props) => {
  const markdown = get(props, 'data.allMarkdownRemark.edges', []);

  const getExcerptParagraph = (excerpt) => {
    const paragraphNode = excerpt.children.find((child) => child.tagName === 'p');

    if (paragraphNode.children.length) {
      const paragraphValue = paragraphNode.children[0].value;
      return paragraphValue;
    }

    return '';
  };

  return (
    <Layout bodyClass="page-blogg">
      <SEO title="Systemutvecklare och konsulter i Malmö/Lund" />
      <Helmet>
        <meta
          name="blogg"
          content="Blogginlägg"
        />
      </Helmet>
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12 pl-2 pr-2">
              <h1>Blogginlägg</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div>
          {markdown.map((edge) => {
            const { name, avatar, key } = edge.node.frontmatter.author;
            const { slug } = edge.node.frontmatter;
            const excerpt = edge.node.excerptAst;
            const excerptParagraph = getExcerptParagraph(excerpt);

            return (
              <div className="mr-1 ml-1" key={edge.node.id}>
                <div className="team card-two">
                  <div className="card-header" style={{ width: '100%' }}>
                    <div className="card-header-left">
                      {avatar && (
                        <div className="card-image">
                          <Link to={`/konsulter/${key}`}>
                            <img
                              alt={name}
                              src={avatar}
                              style={{ width: '100%' }}
                            />
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="card-right">
                      <ul className="card-meta d-flex flex-column">
                        <li><h4 className="align-left"><strong>{name}</strong></h4></li>
                        <li className="hide-on-mobile"><p className="card-text align-left">{excerptParagraph}</p></li>
                        <li className="hide-on-mobile"><Link to={`/blogg/${key}/${slug}`}><p className="card-text">LÄS MER ⯈</p></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="content display-on-mobile">
                  <p>{excerptParagraph}</p>
                  <Link to={`/blogg/${key}/${slug}`}>LÄS MER ⯈</Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

    </Layout >
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/blogg/"}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
          slug
            author {
              name
              avatar
              key
            }
          }
          excerptAst(pruneLength: 190)
          html
        }
      }
    }
  }
`;

Blogg.propTypes = {
  data: P.shape({
    allMarkdownRemark: P.shape({}).isRequired,
  }).isRequired,
};

export default Blogg;
